import './App.css';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { loginRequest } from './AuthConfig';
import { InteractionType } from '@azure/msal-browser';
import 'bootstrap/dist/css/bootstrap.min.css';
import { RenderWidget } from './components/WebChat.js'
import { IPublicClientApplication } from "@azure/msal-browser";

type PageProps = {
  instance: IPublicClientApplication
};

export const Page = ({ instance }: PageProps) => {
  const authRequest = {
    ...loginRequest
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      <div>
        <div><RenderWidget/></div>
      </div>
    </MsalAuthenticationTemplate>
  );
}