import { Configuration, PopupRequest } from "@azure/msal-browser";

const REACT_APP_SA_MSAL_CONFIG_CLIENTID = process.env.REACT_APP_SA_MSAL_CONFIG_CLIENTID as string;
const REACT_APP_SA_MSAL_CONFIG_AUTHORITY = process.env.REACT_APP_SA_MSAL_CONFIG_AUTHORITY as string;
const REACT_APP_SA_MSAL_CONFIG_REDIRECT = process.env.REACT_APP_SA_MSAL_CONFIG_REDIRECT as string;
const REACT_APP_SA_MSAL_SCOPE_1 = process.env.REACT_APP_SA_MSAL_SCOPE_1 as string;
const REACT_APP_SA_MSAL_SCOPE_2 = process.env.REACT_APP_SA_MSAL_SCOPE_2 as string;
const REACT_APP_SA_MSAL_SCOPE_3 = process.env.REACT_APP_SA_MSAL_SCOPE_3 as string;
const REACT_APP_SA_MSAL_SCOPE_4 = process.env.REACT_APP_SA_MSAL_SCOPE_4 as string;

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: REACT_APP_SA_MSAL_CONFIG_CLIENTID,
        authority: REACT_APP_SA_MSAL_CONFIG_AUTHORITY,
        redirectUri: REACT_APP_SA_MSAL_CONFIG_REDIRECT,
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: [REACT_APP_SA_MSAL_SCOPE_1, REACT_APP_SA_MSAL_SCOPE_2, REACT_APP_SA_MSAL_SCOPE_3, REACT_APP_SA_MSAL_SCOPE_4]
};