import React from 'react';
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { CustomNavigationClient } from "./NavigationClient";
import { useHistory } from 'react-router-dom';
import { Page } from './Page';

const REACT_APP_SA_ACCESS_TOKEN_SESSION_STORAGE_KEY = process.env.REACT_APP_SA_ACCESS_TOKEN_SESSION_STORAGE_KEY as string;

type AppProps = {
  pca: IPublicClientApplication
};

export const App = ({ pca }: AppProps) => {
  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);

  const accounts = pca.getAllAccounts();

  let accessToken;
  const accessTokenRequest = {
    scopes: ['User.Read'],
    account: accounts[0]
  };
  pca.acquireTokenSilent(accessTokenRequest).then(function(accessTokenResponse) {
    accessToken = accessTokenResponse.accessToken;
    sessionStorage.setItem(REACT_APP_SA_ACCESS_TOKEN_SESSION_STORAGE_KEY, accessToken);
  }).catch(function (error) {
      console.log(error);
  });

  return (
    <MsalProvider instance={pca}>
        <Page instance={pca}/>
      </MsalProvider>    
  );
}
