import { useState, useEffect } from 'react';
import ReactWebChat, { createDirectLine, createStore } from 'botframework-webchat';
import Grid from '@material-ui/core/Grid';
import './WebChat.css';
import { AuthenticatedTemplate } from "@azure/msal-react";

const REACT_APP_SA_TOKENSERVER_BASICAUTH_USERNAME = process.env.REACT_APP_SA_TOKENSERVER_BASICAUTH_USERNAME;
const REACT_APP_SA_TOKENSERVER_BASICAUTH_PASSWORD = process.env.REACT_APP_SA_TOKENSERVER_BASICAUTH_PASSWORD;
const REACT_APP_SA_TOKENSERVER_GENERATE_TOKEN_ENDPOINT = process.env.REACT_APP_SA_TOKENSERVER_GENERATE_TOKEN_ENDPOINT;
const REACT_APP_SA_HOSTNAME = process.env.REACT_APP_SA_HOSTNAME;
const REACT_APP_SA_ACCESS_TOKEN_SESSION_STORAGE_KEY = process.env.REACT_APP_SA_ACCESS_TOKEN_SESSION_STORAGE_KEY;

const initializeDirectLine = async setDirectLine => {
  let headers = new Headers()
  headers.set('Authorization', 'Basic ' + Buffer.from(REACT_APP_SA_TOKENSERVER_BASICAUTH_USERNAME + ":" + REACT_APP_SA_TOKENSERVER_BASICAUTH_PASSWORD).toString('base64'));
  let options = {
    method : 'POST',
    headers : headers,
  }
  const res = await fetch(REACT_APP_SA_TOKENSERVER_GENERATE_TOKEN_ENDPOINT, options);
  const { token } = await res.json();
  setDirectLine(createDirectLine({ token }));
};

const WebChat = props => {
  const { directLine } = props;

  return directLine
    ? <ReactWebChat {...props} />
    : <h5>Connecting...</h5>
}

export const RenderWidget = () => {

  let token;

  const storeMiddleware = () => next => action => {
    
    token = sessionStorage.getItem(REACT_APP_SA_ACCESS_TOKEN_SESSION_STORAGE_KEY);

    document.getElementsByClassName('webchat__send-box-text-box__input')[0].setAttribute('placeholder', 'Type your question here');
    if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
      store.dispatch({
        type: 'WEB_CHAT/SEND_EVENT',
        payload: {
          name: 'webchat/NewConnect',
          value: {
            accessToken: token 
          }
        }
      });
    }
    return next(action)
  };

  const [directLine, setDirectLine] = useState();
  const [store] = useState(createStore({}, storeMiddleware));

  useEffect(() => {
    initializeDirectLine(setDirectLine);
  }, []);

  const styleOptions = {

    bubbleFromUserBorderRadius: 15,
    bubbleFromUserTextColor: '#005696',
    bubbleFromUserBackground: '#a0cce0',

    bubbleBorderRadius: 15,
    bubbleTextColor: '#005696',
    bubbleBackground: '#FFFFFF',
    
    backgroundColor: '#eaeaea',
    rootHeight: '100%',
    rootWidth: '100%',
    hideUploadButton: true,

    timestampColor: '#000000',

    sendBoxBackground: '#005696',
    sendBoxTextColor:'#FFFFFF',
    sendBoxHeight: '60px',
    sendBoxPlaceholderColor: '#FFFFFF',
    sendBoxButtonColor:'#FFFFFF',
    sendBoxButtonColorOnHover: '#FFFFFF',
    
    botAvatarInitials:'D',
    avatarBorderRadius: 0,
    botAvatarBackgroundColor: 'transparent',
    botAvatarImage: REACT_APP_SA_HOSTNAME + 'MC-Dean_Avatar.png',

    suggestedActionBackground: '#9aea28',
    suggestedActionBorderRadius: 5,
    suggestedActionBorderWidth:0,

    fontFamily: 'Roboto, sans-serif'
  }

  const divStyle = {
    height:'100vh',
    fontSize:'12px',
    fontFamily: 'Roboto, sans-serif'
  };

  return (
    <AuthenticatedTemplate>
      <Grid>
          <div className='container' id='widgetDiv' style={divStyle}>
            <div className='row' id='widgetRenderWebChat' style={{height:'100vh'}}>
              <WebChat directLine={directLine} store={store} styleOptions={styleOptions}/>
            </div>
          </div>
      </Grid>
    </ AuthenticatedTemplate>
  )
};